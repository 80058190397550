<!--
 * @Description: 运维管理系统 监控管理 在线监控  onlineMonitor
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <div class="map-container">
      <div id="map"></div>
    </div>
    <div class="left-select">
      <div>
        <el-select v-model="area"
                   placeholder="请选择">
          <el-option v-for="item in areaList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="jiedao"
                   placeholder="请选择"
                   clearable
                   @change="jiedaoChange"
                   style="width:200px">
          <el-option v-for="item in jiedaoList"
                     :key="item.code"
                     :label="item.regionName"
                     :value="item.code">
          </el-option>
        </el-select>
      </div>
      <div>
        <el-input placeholder="设备在线"
                  readonly>
        </el-input>
      </div>
    </div>
    <div class="right-select">
      <div class="right-item"
           id="monitorBut"
           :style="showMonitor? colorJson[theme].select: colorJson[theme].normal"
           @click="monitorClick">
        <el-image :src="colorJson[theme].a"></el-image>
        <span>高位视频</span>
      </div>
      <div class="right-item"
           id="landLockBut"
           :style="showLandLock? colorJson[theme].select: colorJson[theme].normal"
           @click="landLockClick">
        <el-image :src="colorJson[theme].b"></el-image>
        <span>地锁</span>
      </div>
      <div class="right-item"
           id="barrierGateBut"
           :style="showBarrierGate? colorJson[theme].select: colorJson[theme].normal"
           @click="barrierGateClick">
        <el-image :src="colorJson[theme].c"></el-image>
        <span>道闸</span>
      </div>
      <el-popover placement="bottom"
                  trigger="click">
        <div class="right-pop">
          <el-tree :data="rightTreeData"
                   show-checkbox
                   @check-change="handleCheckChange">
            <div class="tree-item"
                 slot-scope="{node, data}">
              <el-image style="width:13px;height:13px;margin-right:5px"
                        v-if="data.img"
                        :src="data.img"></el-image>
              <span>{{node.label}}</span>
            </div>
          </el-tree>
        </div>
        <div class="right-item"
             id="screenBut"
             slot="reference"
             :style="colorJson[theme].normal">
          <el-image :src="colorJson[theme].d"></el-image>
          <span>诱导屏</span>
        </div>
      </el-popover>
      <div class="right-item"
           id="environmentBut"
           :style="showEnvironment? colorJson[theme].select: colorJson[theme].normal"
           @click="environmentClick">
        <el-image :src="colorJson[theme].e"></el-image>
        <span>环境监控</span>
      </div>
      <div class="right-item"
           id="bayonetBut"
           :style="showBayonet? colorJson[theme].select: colorJson[theme].normal"
           @click="bayonetClick">
        <el-image :src="colorJson[theme].f"></el-image>
        <span>卡口监控</span>
      </div>
    </div>
    <el-dialog :title="popTitle"
               :visible.sync="showMonitorDetail"
               append-to-body
               :modal="false"
               destroy-on-close>
      <monitorDetails :data="data"
                      @cbShow='showDetails'></monitorDetails>
    </el-dialog>
    <div v-show="false">
      <monitorInfoWindow ref="monitorInfoWindow"
                         :id="id"
                         :data="data"
                         :status="data.status"
                         @close="infoWindowClose"
                         @detailsClick="monitorDetailsClick"></monitorInfoWindow>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapState } from 'vuex'
import Amap from "@/common/js/map.js";
import monitorInfoWindow from "./components/monitorInfoWindow";
import monitorDetails from "./components/monitorDetails";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    monitorInfoWindow,
    monitorDetails,
  },
  data () {
    //这里存放数据
    return {
      colorJson: {
        dark: {
          select: 'background:#137fa1;border: none',
          normal: 'background:#143c6d;border: none',
          a: require('@/assets/onlineMonitor/monitor.png'),
          b: require('@/assets/onlineMonitor/landLockBtn.png'),
          c: require('@/assets/onlineMonitor/barrierGate.png'),
          d: require('@/assets/onlineMonitor/guideScreen.png'),
          e: require('@/assets/onlineMonitor/monitor.png'),
          f: require('@/assets/onlineMonitor/monitor.png')
        },
        light: {
          select: 'background:#F5F7FA;border:none',
          normal: 'background:#fff;border: none',
          a: require('@/assets/parkIcon/cameraLight.png'),
          b: require('@/assets/parkIcon/floorLockLight.png'),
          c: require('@/assets/parkIcon/gateLight.png'),
          d: require('@/assets/parkIcon/screenLight.png'),
          e: require('@/assets/parkIcon/cameraLight.png'),
          f: require('@/assets/parkIcon/cameraLight.png')
        }
      },
      map: null,

      area: '渝北区',
      areaList: [{
        value: '渝北区',
        label: '渝北区',
      }],

      jiedao: '',
      jiedaoList: [],
      jiedaoObj: [],

      device: '设备在线',
      // 右侧弹出框树形组件数据
      rightTreeData: {
        label: "name"
      },

      monitorList: [{}],// 高位视频列表
      landLockList: [], // 地锁列表
      barrierGateList: [], // 道闸列表
      environmentList: [],// 环境监控列表
      bayonetList: [],// 卡口监控列表
      firstScreenList: [],// 一级诱导屏

      showMonitor: false,// 监控是否选中
      showMonitorDetail: false, // 是否展示高位视频详情弹窗
      showDetail: false, // 是否展示详情弹窗
      showLandLock: false,// 地锁是否选中
      showBarrierGate: false,// 道闸是否选中
      showScreen: false,  // 诱导屏是否选中
      showEnvironment: false, // 环境监控是否选中
      showBayonet: false,// 卡口监控是否选中
      // 信息窗口绑定变量
      id: '',
      data: {},
      // 高位视频详情弹窗标题
      popTitle: '',
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    })
  },
  //监控data中的数据变化
  watch: {
    theme () {
      this.initMap();
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getRegion()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initMap()
    this.getGuidingScreenTypeList()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 初始化地图
    initMap () {
      this.map = new Amap('map',
        [106.562865, 29.600376],
        // 点位所需的图标
        {
          monitor: { mapIco: require('@/assets/onlineMonitor/monitorOnlinePoint.png'), },
          barrierGate: { mapIco: require('@/assets/onlineMonitor/barrierGateOnline.png'), },
          landLock: { mapIco: require('@/assets/onlineMonitor/landLock.png'), },
          environment: { mapIco: require('@/assets/onlineMonitor/monitorOnlinePoint.png'), },
          bayonet: { mapIco: require('@/assets/onlineMonitor/monitorOnlinePoint.png'), },
          screen: { mapIco: require('@/assets/onlineMonitor/guideScreenOnline.png'), }
        },
        // 信息窗口所需的dom
        {
          monitor: this.$refs.monitorInfoWindow.$el,
          mapIcon: this.$refs.monitorInfoWindow.$el,
          landLock: this.$refs.monitorInfoWindow.$el,
          barrierGate: this.$refs.monitorInfoWindow.$el,
          environment: this.$refs.monitorInfoWindow.$el,
          bayonet: this.$refs.monitorInfoWindow.$el,
          screen: this.$refs.monitorInfoWindow.$el,
        }
      )
      // 点击点标记的回调
      this.map.clickMark = (id, data) => {
        this.id = "";
        this.data = {}
        console.log(id, data.deviceName);
        this.id = id
        this.data = data
      }
    },
    // 获取街道
    getRegion () {
      this.$dataBoards.queryRegion({}).then((res) => {
        this.jiedaoList = res.resultEntity
        res.resultEntity.forEach(item => {
          this.jiedaoObj[item.code] = item
        })
      })
    },
    // 街道发生改变
    jiedaoChange (val) {
      let jiedao = this.jiedaoObj[val]
      // 如果高位视频是激活状态，则更新高位视频坐标
      if (this.showMonitor) {
        this.map.removeMarker('monitor', this.monitorList)
        this.getTopCameraList(() => {
          this.map.setMarker('monitor', this.monitorList)
        })
      }
      if (val) {
        this.map.setCenter(jiedao.longitude, jiedao.latitude)
        this.map.setZoom(17)
      } else {
        this.map.setCenter(106.562865, 29.600376)
        this.map.setZoom(17)
      }
    },
    // 点击高位视频
    monitorClick () {
      this.showMonitor = !this.showMonitor
      if (this.showMonitor) {
        this.getTopCameraList(() => {
          this.map.setMarker('monitor', this.monitorList)
        })
      } else {
        this.map.removeMarker('monitor', this.monitorList)
      }
    },
    // 获取高位视频
    getTopCameraList (callback) {
      const loading = this.$loading({
        target: '#monitorBut',
        lock: false,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      });
      let info = {
        // lotTopCameraType: '1',
        pageNum: 1,
        pageSize: 10000
      }
      this.$cameraDev.queryLotTopCameraList(info).then(res => {
        res.resultEntity.list.forEach(item => {
          item.deviceTypeName = "高位视频"
          item.type = 'monitor'
          item.deviceTypeCode = 'lot_top_camera'
        })
        this.monitorList = res.resultEntity.list
        loading.close()
        if (callback) {
          callback()
        }
      }).catch(() => {
        loading.close()
      })
    },
    // 点击地锁
    landLockClick () {
      this.showLandLock = !this.showLandLock
      if (this.showLandLock) {
        this.getLandLockList(() => {
          this.map.setMarker('landLock', this.landLockList)
        })
      } else {
        this.map.removeMarker('landLock', this.landLockList)
      }
    },
    // 获取地锁
    getLandLockList (callback) {
      const loading = this.$loading({
        target: '#landLockBut',
        lock: false,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      });
      let info = {
        pageNum: 1,
        pageSize: 10000
      }
      this.$floorLock.queryGroundLockList(info).then((res) => {
        res.resultEntity.list.forEach(item => {
          item.deviceTypeName = "地锁"
          item.type = 'landLock'
          item.deviceTypeCode = 'ground_lock'
        })
        this.landLockList = res.resultEntity.list
        console.log(this.landLockList);
        loading.close()
        if (callback) {
          callback()
        }
      }).catch(() => {
        loading.close()
      })
    },
    // 点击道闸
    barrierGateClick () {
      this.showBarrierGate = !this.showBarrierGate
      if (this.showBarrierGate) {
        this.getBarrierGateList(() => {
          this.map.setMarker('barrierGate', this.barrierGateList)
        })
      } else {
        this.map.removeMarker('barrierGate', this.barrierGateList)
      }
    },
    // 获取道闸
    getBarrierGateList (callback) {
      const loading = this.$loading({
        target: '#barrierGateBut',
        lock: false,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      });
      let info = {
        pageNum: 1,
        pageSize: 10000
      }
      this.$barrierGate.queryPassagewayGateList(info).then((res) => {
        res.resultEntity.list.forEach(item => {
          item.deviceTypeName = "道闸"
          item.type = 'barrierGate'
          item.deviceTypeCode = 'passageway_gate'
        })
        this.barrierGateList = res.resultEntity.list
        loading.close()
        if (callback) {
          callback()
        }
      }).catch(() => {
        loading.close()
      })
    },
    // 点击卡口监控
    bayonetClick () {
      this.showBayonet = !this.showBayonet
      if (this.showBayonet) {
        this.getBayonetList(() => {
          this.map.setMarker('monitor', this.bayonetList)
        })
      } else {
        this.map.removeMarker('monitor', this.bayonetList)
      }
    },
    // 点击环境监控
    environmentClick () {
      this.showEnvironment = !this.showEnvironment
      if (this.showEnvironment) {
        this.getEnvironmentList(() => {
          this.map.setMarker('monitor', this.environmentList)
        })
      } else {
        this.map.removeMarker('monitor', this.environmentList)
      }
    },
    // 获取环境监控
    getEnvironmentList (callback) {
      const loading = this.$loading({
        target: '#environmentBut',
        lock: false,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      });
      let info = {
        pageNum: 1,
        pageSize: 10000,
        videoTypeList: ["3", "4"],
      }
      this.$posDev.queryVideoList(info).then((res) => {
        res.resultEntity.list.forEach(item => {
          item.deviceTypeName = "环境监控"
          item.type = 'environment'
          item.deviceTypeCode = 'video'
        })
        this.environmentList = res.resultEntity.list
        loading.close()
        if (callback) {
          callback()
        }
      }).catch(() => {
        loading.close()
      })
    },
    // 获取卡口监控
    getBayonetList (callback) {
      const loading = this.$loading({
        target: '#bayonetBut',
        lock: false,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      });
      let info = {
        pageNum: 1,
        pageSize: 10000,
        videoTypeList: ["1", "2"],
      }
      this.$bayonetMonitor.queryVideoList(info).then((res) => {
        res.resultEntity.list.forEach(item => {
          item.deviceTypeName = "卡口监控"
          item.type = 'bayonet'
          item.deviceTypeCode = 'video'
        })
        this.bayonetList = res.resultEntity.list
        loading.close()
        if (callback) {
          callback()
        }
      }).catch(() => {
        loading.close()
      })
    },
    // 获取诱导屏类型
    getGuidingScreenTypeList () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '37E3D16AA06045859552A5B4AEF4429D',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        response.resultEntity.forEach(item => {
          item.label = item.name
          item.value = item.code
        })
        this.rightTreeData = response.resultEntity
      })
    },
    // 获取诱导屏信息
    getFirstScreenList (callback) {
      const loading = this.$loading({
        target: '#screenBut',
        lock: false,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      });
      let info = {
        pageNum: 1,
        pageSize: 10000
      }
      this.$inductionScreen.queryGuidingScreenList(info).then(res => {
        res.resultEntity.list.forEach(item => {
          item.deviceTypeName = "诱导屏"
          item.type = 'screen'
          item.deviceTypeCode = 'guiding_screen'
        })
        this.firstScreenList = res.resultEntity.list
        loading.close()
        if (callback) {
          callback()
        }
      }).catch(() => {
        loading.close()
      })
    },
    // 诱导屏选择
    handleCheckChange (node, a) {
      if (a && node.code == 3) {
        this.getFirstScreenList(() => {
          this.map.setMarker('screen', this.firstScreenList)
        })
      } else {
        this.map.removeMarker('screen', this.firstScreenList)
      }
    },
    // 点击信息窗口详情按钮
    monitorDetailsClick (id, data) {
      console.log(this.data.deviceName, '222');
      this.data = data
      this.showMonitorDetail = true
      this.popTitle = '设备详情'
    },
    //设备详情展示
    showDetails (value) {
      this.showMonitorDetail = value
    },
    // 点击信息窗口关闭按钮
    infoWindowClose () {
      this.map.closeInfoWindow()
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.body {
  position: relative;
  width: 100%;
  height: 100%;
}
.map-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  #map {
    width: 100%;
    height: 100%;
  }
}
.left-select {
  position: absolute;
  left: 20px;
  top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  height: 40px;
  div {
    width: 98%;
  }
}
.right-select {
  position: absolute;
  width: 460px;
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .right-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    width: 65px;
    height: 50px;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #0f81a2;
    background: #143c6d;
    font-size: 6px;
    line-height: 12px;
    overflow: hidden;
    .el-image {
      width: 20px;
      height: 20px;
    }
    &:active {
      background: #137fa1;
      border: 1px solid #11e1fa;
    }
    &:focus {
      background: #137fa1;
      border: 1px solid #11e1fa;
    }
  }
  .right-pop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 110%;
    height: 110%;
  }
  .tree-item {
    background: green;
  }
}
/deep/.el-loading-spinner {
  margin-top: -10px;
}
</style>